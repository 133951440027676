var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React from "react";
// Components
import GoogleAutocomplete from "./GoogleAutocomplete";
var SearchPopout = /** @class */ (function (_super) {
    __extends(SearchPopout, _super);
    function SearchPopout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.searchInput = React.createRef();
        _this.focusInput = function () { var _a; return (_a = _this.searchInput.current) === null || _a === void 0 ? void 0 : _a.focus(); };
        _this.render = function () {
            var loadingElement = _this.getLoadingElement();
            var results = _this.state.loading ? loadingElement : _this.renderResults();
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { type: "button", className: "search-trigger", onClick: function () { return _this.setState({ popoutOpen: true }, _this.focusInput); } },
                    React.createElement("span", null, "Where would you like to eat or review?"),
                    React.createElement("svg", null,
                        React.createElement("use", { xlinkHref: "#icon--search" }))),
                React.createElement("div", { className: "search-popout", style: {
                        display: _this.state.popoutOpen ? 'block' : 'none'
                    } },
                    React.createElement("div", { className: "search-popout__backdrop", onClick: function () { return _this.setState({ popoutOpen: false }); } }),
                    React.createElement("header", { className: "search-popout__header" },
                        React.createElement("button", { type: "button", className: "back-button", onClick: function () { return _this.setState({ popoutOpen: false }); } },
                            React.createElement("span", null, "Back Button"),
                            React.createElement("svg", { className: "back-button__icon" },
                                React.createElement("use", { xlinkHref: "#icon--back" }))),
                        React.createElement("form", { action: "/", method: "GET", className: "form form--search", autoComplete: "off" },
                            React.createElement("input", { type: "hidden", name: "s" }),
                            React.createElement("input", { name: "location", type: "text", role: "searchbox", onChange: _this.onInputChange, value: _this.state.inputValue, className: "form__control", placeholder: "Where would you like to eat or review?", ref: _this.searchInput }),
                            React.createElement("button", { type: "submit", className: "form__search" },
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--search" }))))),
                    React.createElement("section", { className: "search-popout__results" },
                        React.createElement("div", { className: "inner" }, results)))));
        };
        return _this;
    }
    return SearchPopout;
}(GoogleAutocomplete));
export default SearchPopout;
